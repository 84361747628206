














import {
  defineComponent,
  computed,
} from '@nuxtjs/composition-api';
import moment from 'moment';
import {
  SfRating,
  SfDivider,
} from '@storefront-ui/vue';
export default defineComponent({
  name: 'FortytwoCategoryProductRating',
  components: {
      SfRating,
      SfDivider,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isShowRating: Boolean
  },
  setup(props) {
      const productRating = props.product.rating_summary;
      const productTotalReview = props.product.review_count;
    return {
      productRating,
      productTotalReview
    };
  },
});
